import dayjs from 'dayjs';

export const Utils = {
  responsive_pie: {
    rules: [
      {
        condition: {
          maxWidth: 700
        },
        chartOptions: {
          legend: {
            align: 'center',
            verticalAlign: 'bottom',
            itemMarginTop: 3,
            itemMarginBottom: 3,
            maxHeight: 130
          }
        }
      }
    ]
  },
  humanFileSize: function (bytes, si) {
    const thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }
    const units = si
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    do {
      bytes /= thresh;
      ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1) + ' ' + units[u];
  },
  /**
   *
   * @param {number|bigint} value
   * @param {Intl.NumberFormatOptions['notation']} notation
   * @parama {boolean} round
   * @returns
   */
  formatCurrency: function (value = 0, notation = 'compact', round = false) {
    const formatter = new Intl.NumberFormat('en-US', {
      notation,
      style: 'currency',
      currency: 'EUR',
      compactDisplay: 'long'
    });

    return formatter.format(round ? Math.round(value) : value);
  },
  formatNumber: function (value = 0, compact = true, locale = 'en-US') {
    const formatter = new Intl.NumberFormat(locale, {
      notation: compact ? 'compact' : 'standard'
    });
    return formatter.format(value);
  },
  randomPassword: function (length = 12) {
    if (!window.crypto || !window.crypto.getRandomValues) {
      return Math.random().toString(36).slice(-length);
    }

    return window.crypto
      .getRandomValues(new BigUint64Array(4))
      .reduce(
        (prev, curr, index) =>
          (!index ? prev : prev.toString(36)) +
          (index % 2 ? curr.toString(36).toUpperCase() : curr.toString(36))
      )
      .split('')
      .sort(() => 128 - window.crypto.getRandomValues(new Uint8Array(1))[0])
      .join('')
      .slice(0, length);
  },
  getFormData($form) {
    return window.$.deparam($form.serialize());
  },
  getFormDataReact($form) {
    const queryString = $form.serialize();
    const params = new URLSearchParams(queryString);
    const result = {};

    for (const [key, value] of params) {
      if (key.endsWith('[]')) {
        const cleanKey = key.slice(0, -2);
        if (!result[cleanKey]) {
          result[cleanKey] = [value];
        } else {
          result[cleanKey].push(value);
        }
      } else {
        result[key] = value;
      }
    }

    return result;
  },
  formatDate(date, format = 'DD/MM/YYYY') {
    return dayjs(date).format(format);
  },
  /**
   * @param {string} event_type
   * @param {string} event_description
   * @param {Record<any, any>} event_data
   * @returns {void}
   */
  auditLog(event_type, event_description = undefined, event_data = undefined) {
    window.$.post('/logs/audit', {
      e: event_type,
      ed: event_description,
      d: event_data
    });
  },
  blobToJson(b) {
    let u, x;
    u = URL.createObjectURL(b);
    x = new XMLHttpRequest();
    x.open('GET', u, false); // although sync, you're not fetching over internet
    x.send();
    URL.revokeObjectURL(u);
    return JSON.parse(x.responseText);
  }
};

if (typeof Object.assign !== 'function') {
  Object.assign = function (target) {
    if (target == null) {
      throw new TypeError('Cannot convert undefined or null to object');
    }

    target = Object(target);
    for (let index = 1; index < arguments.length; index++) {
      const source = arguments[index];
      if (source != null) {
        for (const key in source) {
          if (Object.prototype.hasOwnProperty.call(source, key)) {
            target[key] = source[key];
          }
        }
      }
    }
    return target;
  };
}

if (!String.prototype.endsWith) {
  String.prototype.endsWith = function (searchString, position) {
    const subjectString = this.toString();
    if (
      typeof position !== 'number' ||
      !isFinite(position) ||
      Math.floor(position) !== position ||
      position > subjectString.length
    ) {
      position = subjectString.length;
    }
    position -= searchString.length;
    const lastIndex = subjectString.indexOf(searchString, position);
    return lastIndex !== -1 && lastIndex === position;
  };
}

if (!String.prototype.includes) {
  String.prototype.includes = function (search, start) {
    if (typeof start !== 'number') {
      start = 0;
    }

    if (start + search.length > this.length) {
      return false;
    } else {
      return this.indexOf(search, start) !== -1;
    }
  };
}

if (!String.prototype.ucFirst) {
  String.prototype.ucFirst = function () {
    return this.toString().charAt(0).toUpperCase() + this.toString().slice(1).toLowerCase();
  };
}

window.Utils = Utils;

window.loader = {
  el: window.$('.filter-button'),
  show() {
    this.el.data('text', this.el.html());
    this.el.html(`
        <svg id="filter-loader" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 38 38" stroke="#fff">
            <g fill="none" fill-rule="evenodd">
                <g transform="translate(1 1)" stroke-width="2">
                    <circle stroke-opacity=".5" cx="18" cy="18" r="18"/>
                    <path d="M36 18c0-9.94-8.06-18-18-18">
                        <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s"
                                          repeatCount="indefinite"/>
                    </path>
                </g>
            </g>
        </svg>
        `);
    this.el.attr('disabled', true);
  },
  hide() {
    this.el.html(this.el.data('text'));
    this.el.attr('disabled', false);
  }
};
